import React from 'react';
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "../components/general/page-header"
import { parseBlogNode } from '../helpers/blogparser';

const Blog = ({
  data: {
    allFeedBlog: { edges },
  },
}) => {

  const pageData = {
    title: "Blog",
    description: "Writing about leadership, management, engineering and life"
  }

  return (
    <main >
      <title>{pageData.title}</title>
      <Layout page={pageData} classes="blog-page">
      <PageHeader texts={pageData} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-8 md:p-0">
          {edges.map((edge, i) => {
            const post = parseBlogNode(edge.node);

            return (
              <figure className="bg-slate-100 rounded-xl p-8 no-underline" key={`blog-${i}`}>
                <img
                  className="mx-auto"
                  height="200px"
                  alt={post.title}
                  src={post.imageUrl}
                />
                <a href={post.link} className="features-small-item p-0 pointer no-underline">
                  <div className="space-y-4">
                    <blockquote>
                      <p className="text-lg font-medium">
                        {post.title}
                      </p>
                    </blockquote>
                    <figcaption className="font-medium">
                      <div className="text-slate-500">
                        {post.date}
                      </div>
                    </figcaption>
                    <div>
                      {post.tags.map((tag, j) => {
                        return (
                          <span className="text-xs inline-block py-1 px-2 rounded text-slate-600 bg-slate-200 last:mr-0 mr-1 mb-1" key={`blog-${i}-label-${j}`}>
                            {tag}
                          </span>
                        )
                      })}
                    </div>
                  </div>
                </a>
              </figure>
            )}
          )}
        </div>
      </Layout>
    </main>
  )
}

export default Blog

export const blogQuery = graphql`
  query BlogIndex{
    allFeedBlog(sort: {order: DESC, fields: isoDate}) {
      edges {
        node {
          link
          isoDate(formatString: "YYYY-MM-DD")
          title
          categories
          content {
            encoded
          }
        }
      }
    }
  }
`;
